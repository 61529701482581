* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}

html {
  background-color: #525659;
}

.my-pdf {
  height: 93vh;
  overflow: hidden;
}

.menu {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
}

.pdf-download-link {
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  color: black;
  text-decoration: none;
}
